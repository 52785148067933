// react libraries import
import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const DialogTextBox = ({ data, isHome }) => {


    const [ showDialog, setShowDialog ] = useState(false);


    const handleClick = () => {
        setShowDialog(false);
    }

    return (
        <div>
            {/** Dialog Box */}
            { /** Overlay Effect */}
            { showDialog ? 
                <div
                    className="fixed hidden inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
                    id="my-modal"
                >
                    Kei na kei ta xa hai
                </div>
                :
                null
            }

        <div role="button" tabIndex={0} className="w-100 mx-auto p-2 md:p-8 md:pt-4 my-2 md:my-4 flex flex-col items-start bg-white sm-rounded" onClick={handleClick} onKeyDown={handleClick}>
            <div className="w-full flex items-center justify-center md:justify-between">
                <div className="my-4 flex">
                    <StaticImage
                        src="../images/notice.png"
                        width={100}
                        quality={100}
                        className="w-5"
                        formats={["auto", "webp", "avif"]}
                        alt="Notice"
                    />
                    <div className={ `${isHome ? 'hidden md:block' : 'block'} ml-2 font-semibold`}>
                        {data.title}
                    </div>
                </div>
                <div className="ml-2 text-right">
                    {data.date}
                </div>
            </div>
            <div className="w-full py-0 md:py-4 flex flex-col md:flex-row items-center justify-between">
                <div className={`${isHome ? 'hidden md:block leading-5 h-10 md:w-5/6' : 'block'} ml-2 w-full  overflow-hidden text-sm`}>
                    {data.message}
                </div>
                <div className={ `${isHome ? 'block md:hidden' : 'hidden'} font-semibold`}>
                    {data.title}
                </div>
                <div className={`${isHome ? 'mx-auto md:mx-0 md:ml-2 mt-4 md:mt-0 text-base font-semibold text-blue2 cursor-pointer hover:text-third' : 'hidden' }`}>
                    Read
                </div>
            </div>
        </div>

        </div>
    );
}

export default DialogTextBox;