//react libraries import
import React, { useState } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Gallery = ({medias}) => {    


    // as graphql sometimes gives null as image sharp,
    //we have to filter such null values
    medias = medias.filter((media) => media !== null || media !== undefined);

    return(
        <div className="flex flex-wrap -mx-2 px-2 overflow-hidden">
            {
                medias.map( ( media, index ) => {

                    return (
                        <Image media={media} key={index}/>
                    )
                })
            }
        </div>
    );

}


const Image = ({media}) => {

    const [ showOverlayMessage, setShowOverlayMessage ] = useState(false);
    const imageData = getImage(media);

    return(
        <div
            onMouseOver={ () => setShowOverlayMessage(true) }
            onFocus={ () => setShowOverlayMessage(true) }
            onMouseEnter={ () => setShowOverlayMessage(true) }
            onMouseLeave={ () => setShowOverlayMessage(false) }
            className="inline-block my-2 px-2 w-1/2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/4 transition duration-500 transform hover:scale-110" 
        >
            { showOverlayMessage ?
                <div 
                    className="text-sm absolute w-19/20 inset-x-2 text-center bottom-0 z-40 font-semibold bg-overlay p-4 text-white"
                >   
                    Event Name
                </div>
                :
                null
            }
            <GatsbyImage className="block hover:rounded-md" image={imageData} alt="Plant" />
        </div>
    )
}

export default Gallery;