//react libraries import
import React from "react";
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

// custom import
import BannerCarousel from "../components/carousel/BannerCarousel";
import DialogTextBox from '../components/dialogTextBox';
import Gallery from '../components/gallery';
import notices from '../components/notices.json';

// markup
const IndexPage = () => {


  const medias = useStaticQuery(
    graphql`
      query {
        photos: allFile(filter: {relativePath: {regex: "/gallery/"}}) {
          nodes {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 100, height: 250, width: 400)
            }
          }
        }
      }      
    `
  )

  return (
      <main>
        <div style={{height: '86vh'}}>
          <BannerCarousel/>
        </div>
        
        {/** Core Values */}
        <div className="w-full py-10 px-4 flex flex-col items-center bg-fourth shadow-2xl shadow-inner">
          <h3 className="text-center text-2xl text-primary font-bold">Core Values</h3>
          <div className="w-full mt-4 px-8 grid grid-flow-col grid-cols-2 md:grid-cols-4 grid-rows-2 md:grid-rows-1 gap-4 justify-around">
            <div className="flex flex-col items-center">
              <StaticImage
                src="../images/responsibility.png"
                width={100}
                quality={100}
                className="my-4 w-14 md:w-18"
                formats={["auto", "webp", "avif"]}
                alt="School Logo"
              />
              <span className="ml-2 text-primary font-semibold text-center">Respect & Responsibility</span>
            </div>
            <div className="flex flex-col items-center">
              <StaticImage
                src="../images/achievement.png"
                width={100}
                quality={100}
                className="my-4 w-14 md:w-18"
                formats={["auto", "webp", "avif"]}
                alt="School Logo"
              />
              <span className="ml-2 text-primary font-semibold text-center">Excellence</span>
            </div>
            <div className="flex flex-col items-center">
              <StaticImage
                src="../images/collaboration.png"
                width={100}
                quality={100}
                className="my-4 w-14 md:w-18"
                formats={["auto", "webp", "avif"]}
                alt="School Logo"
              />
              <span className="ml-2 text-primary font-semibold text-center">Collaboration & Communication</span>
            </div>
            <div className="flex flex-col items-center">
              <StaticImage
                src="../images/knowledge.png"
                width={100}
                quality={100}
                className="my-4 w-14 md:w-18"
                formats={["auto", "webp", "avif"]}
                alt="School Logo"
              />
              <span className="ml-2 text-primary font-semibold text-center">Knowledge</span>
            </div>
          </div>
        </div>


        {/** About us Section */}
        <div className="w-full pt-12 pb-4 px-6 bg-white">
          <div className="text-center font-bold text-primary text-2xl">
            Welcome to Shree Mahendra Secondary School
          </div>
          <div className="p-4 my-8 mx-auto rounded-md"> 
            <div className="text-justify mb-2 text-base">
              Shree Mahendra Secondary School situated in Machhapuchchhre-4, Lahachok, Kaski is a community school established in 2016 B.S.
              It aims to provide quality education in english medium from pre-primary to class 10 in a child friendly environment. We have got all the
              infrastructures and resources required for the personal and dynamic growth of the students.
            </div>
            <div className="my-4 text-center tracking-wide text-2xl font-thin">
              Our goal for this website is to provide the best possible information regarding our 
              schools and programs in order to communicate effectively with all of our constituents.
            </div>
            <div className="text-center mb-2 text-base">
              I hope that you find it to be accessible and useful, and I invite you to share any suggestions regarding the site by clicking on the web feedback form below.
              <br></br>
              If you have questions, please don't hesitate to contact our district office or one of our schools for assistance. It will be our pleasure to help you.
              <br></br>
              <br></br>
              Respectfully,
              <br></br>
              Jagannath Adhikari
              <br></br>
              Principle, Mahendra Secondary School
            </div>
          </div>
        </div>


        {/** Notice Section */}
        <div className="w-full pt-12 pb-4 px-6 bg-light-primary">
          <div className="text-center font-bold text-primary text-2xl">
            Events & Notices
          </div>
          <div className="w-full mt-4 px-2 grid grid-flow-col grid-cols-1 md:grid-cols-2 grid-rows-5 md:grid-rows-3 gap-4 justify-around">
            {
              notices.map( ( notice, index ) => {
                return (
                  <DialogTextBox key={index} data={notice} isHome={true}/>
                );
              })
            }
          </div>
        </div>


        {/** Gallery Section */}
        <div className="w-full pt-12 pb-12 px-8 bg-white">
          <div className="text-center font-bold text-primary text-2xl mb-6">
            Gallery
          </div>
          <Gallery medias={medias.photos.nodes}/>
        </div>
      </main>
  )
}

export default IndexPage;
