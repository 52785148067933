// react libraries import
import React from 'react';

//libraries for background image
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundSlider from 'gatsby-image-background-slider';

const BannerCarousel = () => {

  const images = useStaticQuery(
    graphql`
      query {
        backgrounds: allFile(
          filter: {relativePath: {regex: "/banner/"}, childImageSharp: {gatsbyImageData: {}}}
        ) {
          nodes {
            relativePath
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `
  )



  return (
    <BackgroundSlider 
      query={images}
      initDelay={2} // delay before the first transition (if left at 0, the first image will be skipped initially)
      transition={2} // transition duration between images
      duration={6} // how long an image is shown
      // pass down standard element props
      className={'top-22 md:top-32'}
      style={{
        position: 'absolute',
      }}
    >
      {/* Captions in sync with background images*/}
      

    </BackgroundSlider>
  )
}

export default BannerCarousel;